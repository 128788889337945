import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"
import { Link } from "gatsby";
import imgPassenger from '../../images/news/heckmondwike-grammar-school-bus.jpg'

class NewsPage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Heckmondwike grammar school annual bus pass at Tetley's Coaches" description="Are you tired of worrying about getting to school on time? Are you looking for a hassle-free way to travel to and from Heckmondwike Grammar School? Look no further! Our exclusive school bus services offer the perfect solution for your transportation needs!" />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Heckmondwike Grammar School bus passes on sale!</h1>
              <p className="news-article-date">March 2024</p>
            </div>

            <img src={imgPassenger} alt="" />

            <div className="news-article-body">
              <p>Are you tired of worrying about getting to school on time? Are you looking for a hassle-free way to
                travel to and from Heckmondwike Grammar School? Look no further! Our exclusive school bus
                services offer the perfect solution for your transportation needs!</p>
              <p>
                <Link to="/hgs11-service/">
                  HGS11 – Dexter Pub – Tree tops Community Centre – Moor Allerton District Centre
                </Link>
              </p>
              <p>
                <Link to="/hgs12-service/">
                  HGS12 - Kirkstall – Horsforth – Adel – Alwoodley – Moortown – Oakwood – Roundhay - Morley
                </Link>
              </p>
              <p>
                <Link to="/hgs13-service/">
                  HGS13 – Colton – Crossgates – Moor Allerton – Alwoodley – Carr Manor - Scott Hall – Holbeck-Birstall
                </Link>
              </p>
              <h3 style={{ marginBottom: 10, fontSize: 20 }}>Why Choose Our Bus Passes?</h3>
              <ul>
                <li>
                  Convenient Transportation: Say goodbye to the stress of finding a ride or waiting for public
                  transport. Our bus passes ensure you reach school comfortably and punctually every day.
                </li>
                <li>
                  Cost-Effective: With our affordable bus pass, you can save money on daily travel expenses.
                  Invest in your education without breaking the bank!
                </li>
                <li>
                  Reliable Service: Rest assured, our buses are operated by experienced drivers who prioritise
                  safety and punctuality. You can rely on us for a smooth and secure journey.
                </li>
                <li>
                  Flexible Options: You can pay for your annual pass via debit/credit card or flexible monthly
                  payments, we have you covered!
                </li>
                <li>
                  Community Connection: Join your fellow students on our buses and enjoy the camaraderie of
                  traveling together. Forge new friendships and make your journey to school more enjoyable!
                </li>
                <li>
                  Our new track feature allows students to stay informed in real time by viewing the vehicles’
                  location via the E-Ticket.
                </li>
                <li>
                  Boarding notifications informs parents their child has boarded.
                </li>
                <li>
                  Responsive operations team, available by phone or email 24 hours a day 7 days a week
                </li>
              </ul>
              <p><strong>Don&#39;t miss out on this fantastic opportunity to streamline your daily commute! Purchase your
                bus pass today and experience the convenience and comfort of traveling with Tetley's Coaches.</strong></p>
              <p>For more information and to secure your bus pass, visit our website links above or <Link to="/contact/">contact our
                dedicated team</Link>. Hurry, seats are limited!</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
